@import '@styles/mixins.style';

.acf-news {
  position: relative;
  &__cards {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-5);

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
